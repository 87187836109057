// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NMsAgs6ShoxUalfYq18b{display:inline-block}.dptSxp6WickOkyQnNoZe{max-width:200px;line-height:20px;white-space:normal}.MdVooIpT2aBsTmMh22oP{padding-bottom:3px;opacity:1}.q0n2Fip6KU9VWbE47xgB{margin:0;padding:0;border:none;border-radius:0;background-color:rgba(0,0,0,0);margin-right:5px}.q0n2Fip6KU9VWbE47xgB:hover{box-shadow:none}.q0n2Fip6KU9VWbE47xgB:active{border:none !important}", "",{"version":3,"sources":["webpack://./src/templates/project-template-workspace-icon.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA,sBACE,oBAAA,CAGF,sBACE,eAAA,CACA,gBAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CACA,SAAA,CAGF,sBCCE,QAAA,CACA,SAAA,CACA,WAAA,CACA,eAAA,CACA,8BAAA,CDHA,gBAAA,CCKA,4BACE,eAAA,CAGF,6BACE,sBAAA","sourcesContent":["@import '@cdo/apps/mixins.scss';\n@import 'color.scss';\n\n.container {\n  display: inline-block;\n}\n\n.tooltip {\n  max-width: 200px;\n  line-height: 20px;\n  white-space: normal;\n}\n\n.projectTemplateIcon {\n  padding-bottom: 3px;\n  opacity: 1;\n}\n\n.projectTemplateButton {\n  @include remove-button-styles;\n  margin-right: 5px;\n}\n","// Removes (most) default button styling. Useful for retaining a clickable component's\n// original presentation when converting it to a <button>\n@mixin remove-button-styles(\n  $color: false,\n  $font-size: false,\n  $line-height: false\n) {\n  @if $color {\n    color: $color;\n  }\n\n  @if $font-size {\n    font-size: $font-size;\n  }\n\n  @if $line-height {\n    line-height: $line-height;\n  }\n\n  margin: 0;\n  padding: 0;\n  border: none;\n  border-radius: 0;\n  background-color: transparent;\n\n  &:hover {\n    box-shadow: none;\n  }\n\n  &:active {\n    border: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NMsAgs6ShoxUalfYq18b",
	"tooltip": "dptSxp6WickOkyQnNoZe",
	"projectTemplateIcon": "MdVooIpT2aBsTmMh22oP",
	"projectTemplateButton": "q0n2Fip6KU9VWbE47xgB"
};
export default ___CSS_LOADER_EXPORT___;
