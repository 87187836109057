/* eslint-disable */

// This is a generated file and SHOULD NOT BE EDITED MANUALLY!!
// Contents are generated as part of grunt build
// Source of truth is lib/cdo/shared_constants.rb and files in lib/cdo/shared_constants/

export const GamelabBlocks = {
  "draw": null,
  "drawSprites": null,
  "playSound": null,
  "stopSound": null,
  "playSpeech": null,
  "keyDown": null,
  "keyWentDown": null,
  "keyWentUp": null,
  "mouseDidMove": null,
  "mouseDown": null,
  "mouseIsOver": null,
  "mouseWentDown": null,
  "mouseWentUp": null,
  "mousePressedOver": null,
  "showMobileControls": null,
  "World.mouseX": null,
  "World.mouseY": null,
  "World.frameRate": null,
  "World.frameCount": null,
  "World.seconds": null,
  "World.width": null,
  "World.height": null,
  "World.allSprites": null,
  "camera.on": null,
  "camera.off": null,
  "camera.isActive": null,
  "camera.mouseX": null,
  "camera.mouseY": null,
  "camera.x": null,
  "camera.y": null,
  "camera.zoom": null,
  "comment_GameLab": null,
  "var sprite = createSprite": null,
  "setAnimation": null,
  "x": null,
  "y": null,
  "velocityX": null,
  "velocityY": null,
  "scale": null,
  "sprite.height": null,
  "sprite.width": null,
  "visible": null,
  "rotation": null,
  "rotationSpeed": null,
  "rotateToDirection": null,
  "debug": null,
  "isTouching": null,
  "collide": null,
  "displace": null,
  "overlap": null,
  "bounce": null,
  "bounceOff": null,
  "bounciness": null,
  "setCollider": null,
  "createEdgeSprites": null,
  "shapeColor": null,
  "tint": null,
  "alpha": null,
  "setVelocity": null,
  "getDirection": null,
  "getSpeed": null,
  "setSpeedAndDirection": null,
  "pointTo": null,
  "mirrorX": null,
  "mirrorY": null,
  "getScaledWidth": null,
  "getScaledHeight": null,
  "lifetime": null,
  "nextFrame": null,
  "pause": null,
  "play": null,
  "setFrame": null,
  "depth": null,
  "destroy": null,
  "comment_Sprites": null,
  "var group = createGroup": null,
  "add": null,
  "remove": null,
  "clear": null,
  "contains": null,
  "get": null,
  "group.isTouching": null,
  "group.bounce": null,
  "group.bounceOff": null,
  "group.collide": null,
  "group.displace": null,
  "group.overlap": null,
  "maxDepth": null,
  "minDepth": null,
  "destroyEach": null,
  "pointToEach": null,
  "setAnimationEach": null,
  "setColorEach": null,
  "setColliderEach": null,
  "setDepthEach": null,
  "setHeightEach": null,
  "setLifetimeEach": null,
  "setMirrorXEach": null,
  "setMirrorYEach": null,
  "setRotateToDirectionEach": null,
  "setRotationEach": null,
  "setRotationSpeedEach": null,
  "setScaleEach": null,
  "setSpeedAndDirectionEach": null,
  "setTintEach": null,
  "setVelocityEach": null,
  "setVelocityXEach": null,
  "setVelocityYEach": null,
  "setVisibleEach": null,
  "setWidthEach": null,
  "comment_Groups": null,
  "background": null,
  "fill": null,
  "noFill": null,
  "stroke": null,
  "strokeWeight": null,
  "noStroke": null,
  "rgb": null,
  "rect": null,
  "ellipse": null,
  "text": null,
  "textAlign": null,
  "textFont": null,
  "textSize": null,
  "arc": null,
  "line": null,
  "point": null,
  "regularPolygon": null,
  "shape": null,
  "comment_Drawing": null,
  "forLoop_i_0_4": null,
  "ifBlock": null,
  "ifElseBlock": null,
  "whileBlock": null,
  "comment_Control": null,
  "addOperator": null,
  "subtractOperator": null,
  "multiplyOperator": null,
  "divideOperator": null,
  "moduloOperator": null,
  "equalityOperator": null,
  "inequalityOperator": null,
  "greaterThanOperator": null,
  "greaterThanOrEqualOperator": null,
  "lessThanOperator": null,
  "lessThanOrEqualOperator": null,
  "andOperator": null,
  "orOperator": null,
  "notOperator": null,
  "randomNumber_min_max": null,
  "mathRound": null,
  "mathAbs": null,
  "mathMax": null,
  "mathMin": null,
  "mathRandom": null,
  "mathPow": null,
  "mathSqrt": null,
  "comment_Math": null,
  "declareAssign_x": null,
  "declareNoAssign_x": null,
  "assign_x": null,
  "console.log": null,
  "console.clear": null,
  "comment_Variables": null,
  "functionParams_none": null,
  "functionParams_n": null,
  "callMyFunction": null,
  "callMyFunction_n": null,
  "return": null,
  "comment": null
};
