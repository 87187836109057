// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoY_F9p72OXkgIm5IS19{display:none;position:relative;z-index:1000;width:100%;height:100%;bottom:0}.bZkti3MP0Hlfk2Lndp9C{position:absolute;top:10px;right:10px;z-index:1}.qjkqY8Al7GpJ7kkRpUuD{left:10px;right:0}.SUSNx2uMs5Sz5tgNV76i{float:right}.uTQ0GOvkUlrngrGCSTuv{float:left}", "",{"version":3,"sources":["webpack://./src/blockly/components/modal-function-editor.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,iBAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,SAAA,CAGF,sBACE,SAAA,CACA,OAAA,CAGF,sBACE,WAAA,CAGF,sBACE,UAAA","sourcesContent":["@import 'color.scss';\n\n.container {\n  display: none;\n  position: relative;\n  z-index: 1000;\n  width: 100%;\n  height: 100%;\n  bottom: 0;\n}\n\n.toolbar {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  z-index: 1;\n}\n\n.toolbarRtl {\n  left: 10px;\n  right: 0;\n}\n\n.buttons {\n  float: right;\n}\n\n.buttonsRtl {\n  float: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NoY_F9p72OXkgIm5IS19",
	"toolbar": "bZkti3MP0Hlfk2Lndp9C",
	"toolbarRtl": "qjkqY8Al7GpJ7kkRpUuD",
	"buttons": "SUSNx2uMs5Sz5tgNV76i",
	"buttonsRtl": "uTQ0GOvkUlrngrGCSTuv"
};
export default ___CSS_LOADER_EXPORT___;
